import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { WithErrorLabel } from "../FormError"
import Styles from "./DatePicker.module.scss"

import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'

// const [startDate, setStartDate] = useState(null);

const InputD = ({ label, small, required, field, ...props }) => {
  delete props.form
  // const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const handleChange = (date) => {
    setDate(date);

    console.log(date);
  };

  return (

    <div
      className={classNames(Styles.wrapperbutton2, {
        [Styles.small]: small,
      })}
    >
    <DatePicker
      // selected={startDate}
      selected={date}
      // onChange={(date => setFieldValue("date", date))}
      // onChange={(date) => setStartDate(date)}
      onChangeRaw={(e) => e.preventDefault()}
      // onChange={date => setFieldValue("date", date)}
      showYearDropdown={true}
      showYearPicker
      dateFormat="yyyy"
      name="born"
      // isClearable={true}
      // input = {true}
      {...field}
      {...props}

      onChange={handleChange}
      // minDate={new Date("02-01-1900")}
      // maxDate={new Date("02-29-2020")}

      // withPortal
    />
      {label && (
        <label>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
    </div>

    // <div
    //   className={classNames(Styles.wrapperbutton2, {
    //     [Styles.small]: small,
    //   })}
    // >
    //   {/*<input {...field} {...props} />*/}
    //   {/*{label && (*/}
    //   {/*  <label>*/}
    //   {/*    {label}*/}
    //   {/*    {required && <sup>*</sup>}*/}
    //   {/*  </label>*/}
    //   {/*)}*/}
    // </div>
  )
}

InputD.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
}

export default WithErrorLabel(InputD)
