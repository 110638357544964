import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { WithErrorLabel } from "../FormError"
import Styles from "./Input.module.scss"

const Input = ({ label, small, required, field, ...props }) => {
  delete props.form

  return (
    <div
      className={classNames(Styles.wrapperbutton, {
        [Styles.small]: small,
      })}
    >
      <input {...field} {...props} />
      {label && (
        <label>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
}

export default WithErrorLabel(Input)
