import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import * as Yup from "yup"
import formik, { Form, Formik, Field } from "formik"


import {useFormik} from "formik"

import Layout from "../Components/Layout"
import Box from "../Components/Box2"
import Input from "../Components/Input"
import Checkbox from "../Components/Checkbox"
import DatePi from "../Components/DatePicker"

import { RaisedButton } from "../Components/Buttons"
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import props from "prop-types"


import Select from 'react-select';
import Creatable from 'react-select/creatable';

import CreatableSelect from 'react-select/creatable';


const FormDatePicker = (props) => {
  return (
    <Field name="startDate">
      {({ field, meta, form: { setFieldValue } }) => {
        return (
          <DatePicker
            {...field}
            selected={field.value || null}
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
          />
        );
      }}
    </Field>
  );
};


function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref)
    }
  })
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);

import ThumbIlliustration from "../assets/images/thumb-illiustration.png"
import * as values from "yup"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Laukas yra privalomas"),
  surname: Yup.string().required("Laukas yra privalomas"),
  email: Yup.string()
    .email("Neteisingas el.pašto formatas")
    .required("Laukas yra privalomas"),
  emailrep: Yup.string().required("Laukas yra privalomas").equalTo(Yup.ref('email')),
  //born: Yup.string().required("Laukas yra privalomas"),
  //bborn: Yup.string().required("Laukas yra privalomas"),

  //gender: Yup.string(),  //.required("Laukas yra privalomas")

  //date: Yup.string().required("Laukas yra privalomas"),
  //city: Yup.string(),//.required("Laukas yra privalomas"),
  age: Yup.number("Ne skaitinė reikšmė").required("Laukas yra privalomas"),

  //startDate: Yup.string().required("Laukas yra privalomas"),
  // occupation: Yup.string().required("Laukas yra privalomas"),
  // phone: Yup.string().required("Laukas yra privalomas"),

  // rules: Yup.bool(true).oneOf([true]),
  privacy: Yup.bool(true).oneOf([true]),
})

export default () => {

  // let FormObserver
  // FormObserver = () => {
  //   const { values } = useFormikContext()
  //   useEffect(() => {
  //     console.log("FormObserver::values", values)
  //   }, [values])
  //   return null
  // }


  const optionsClass1 = [
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' }
  ]

  const optionsClass2 = [

    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ]

  const optionsGender = [
    { value: 'male', label: 'Vyras' },
    { value: 'female', label: 'Moteris' },
    { value: 'other', label: 'Kita' },
    { value: 'undefined', label: 'Nenoriu atskleisti' }
  ]

  const optionsEducation = [
    { value: '1', label: 'Pradinis' },
    { value: '2', label: 'Pagrindinis (nebaigtas vidurinis)' },
    { value: '3', label: 'Vidurinis' },
    { value: '4', label: 'Profesinis (profesinė mokykla, vidurinis su profesine kvalifikacija)' },
    { value: '5', label: 'Aukštesnysis (technikumas, aukštesniosios mokyklos)' },
    { value: '6', label: 'Neuniversitetinis aukštasis (kolegija)' },
    { value: '7', label: 'Universitetinis aukštasis – bakalauro laipsnis' },
    { value: '8', label: 'Universitetinis aukštasis – magistro laipsnis' },
    { value: '9', label: 'Universitetinis aukštasis – mokslų daktaras(–ė)' },
  ]

  const optionsCities = [
    { value: 'Vilnius', label: 'Vilnius' },
    { value: 'Kaunas ', label: 'Kaunas' },
    { value: 'Klaipėda', label: 'Klaipėda' },
    { value: 'Šiauliai', label: 'Šiauliai' },
    { value: 'Panevėžys', label: 'Panevėžys' },
    { value: 'Alytus', label: 'Alytus' },
    { value: 'Marijampolė', label: 'Marijampolė' },
    { value: 'Mažeikiai', label: 'Mažeikiai' },
    { value: 'Utena', label: 'Utena' },
    { value: 'Jonava', label: 'Jonava' },
    { value: 'Kėdainiai', label: 'Kėdainiai' },
    { value: 'Telšiai', label: 'Telšiai' },
    { value: 'Tauragė', label: 'Tauragė' },
    { value: 'Ukmergė', label: 'Ukmergė' },
    { value: 'Visaginas', label: 'Visaginas' },
    { value: 'Plungė', label: 'Plungė' },
    { value: 'Kretinga', label: 'Kretinga' },
    { value: 'Šilutė', label: 'Šilutė' },
    { value: 'Palanga', label: 'Palanga' },
    { value: 'Radviliškis', label: 'Radviliškis' },
    { value: 'Gargždai', label: 'Gargždai' },
    { value: 'Rokiškis', label: 'Rokiškis' },
    { value: 'Druskininkai', label: 'Druskininkai' },
    { value: 'Elektrėnai', label: 'Elektrėnai' },
    { value: 'Kuršėnai', label: 'Kuršėnai' },
    { value: 'Grigiškės', label: 'Grigiškės' },
    { value: 'Biržai', label: 'Biržai' },
    { value: 'Lentvaris', label: 'Lentvaris' },
    { value: 'Garliava', label: 'Garliava' },
    { value: 'Jurbarkas', label: 'Jurbarkas' },
    { value: 'Vilkaviškis', label: 'Vilkaviškis' },
    { value: 'Joniškis', label: 'Joniškis' },
    { value: 'Anykščiai', label: 'Anykščiai' },
    { value: 'Varėna', label: 'Varėna' },
    { value: 'Prienai', label: 'Prienai' },
    { value: 'Kelmė', label: 'Kelmė' },
    { value: 'Naujoji Akmenė', label: 'Naujoji Akmenė' },
    { value: 'Šalčininkai', label: 'Šalčininkai' },
    { value: 'Pasvalys', label: 'Pasvalys' },
    { value: 'Zarasai', label: 'Zarasai' },
    { value: 'Kupiškis', label: 'Kupiškis' },
    { value: 'Kazlų Rūda', label: 'Kazlų Rūda' },
    { value: 'Molėtai', label: 'Molėtai' },
    { value: 'Pabradė', label: 'Pabradė' },
    { value: 'Skuodas', label: 'Skuodas' },
    { value: 'Domeikava', label: 'Domeikava' },
    { value: 'Šakiai', label: 'Šakiai' },
    { value: 'Švenčionėliai', label: 'Švenčionėliai' },
    { value: 'Šilalė', label: 'Šilalė' },
    { value: 'Pakruojis', label: 'Pakruojis' },
    { value: 'Vievis', label: 'Vievis' },
    { value: 'Kybartai', label: 'Kybartai' },
    { value: 'Trakai', label: 'Trakai' },
    { value: 'Raudondvaris', label: 'Raudondvaris' },
    { value: 'Skaidiškės', label: 'Skaidiškės' },
    { value: 'Švenčionys', label: 'Švenčionys' },
    { value: 'Rudamina', label: 'Rudamina' },
    { value: 'Kalvarija', label: 'Kalvarija' },
    { value: 'Lazdijai', label: 'Lazdijai' },
    { value: 'Pagiriai', label: 'Pagiriai' },
    { value: 'Žiežmariai', label: 'Žiežmariai' },
    { value: 'Pervalka', label: 'Pervalka' },
    { value: 'Rietavas', label: 'Rietavas' },
    { value: 'Ringaudai', label: 'Ringaudai' },
    { value: 'Ariogala', label: 'Ariogala' },
    { value: 'Didžioji Reišė', label: 'Didžioji Reišė' },
    { value: 'Nemėžis', label: 'Nemėžis' },
    { value: 'Akmenė', label: 'Akmenė' },
    { value: 'Dembava', label: 'Dembava' },
    { value: 'Nida', label: 'Nida' },
    { value: 'Birštonas', label: 'Birštonas' },
    { value: 'Pilviškiai', label: 'Pilviškiai' },
    { value: 'Venta', label: 'Venta' },
    { value: 'Viekšniai', label: 'Viekšniai' },
    { value: 'Avižieniai', label: 'Avižieniai' },
    { value: 'Rukla', label: 'Rukla' },
    { value: 'Valčiūnai', label: 'Valčiūnai' },
    { value: 'Baisogala', label: 'Baisogala' },
    { value: 'Taurai', label: 'Taurai' },
    { value: 'Tytuvėnai', label: 'Tytuvėnai' },
    { value: 'Viečiūnai', label: 'Viečiūnai' },
    { value: 'Teleičiai', label: 'Teleičiai' },
    { value: 'Viduklė', label: 'Viduklė' },
    { value: 'Švėkšna', label: 'Švėkšna' },
    { value: 'Krekenava', label: 'Krekenava' },
    { value: 'Gelgaudiškis', label: 'Gelgaudiškis' },
    { value: 'Karmėlava II', label: 'Karmėlava II' },
    { value: 'Ramučiai', label: 'Ramučiai' },
    { value: 'Ežerėlis', label: 'Ežerėlis' },
    { value: 'Babtai', label: 'Babtai' },
    { value: 'Josvainiai', label: 'Josvainiai' },
    { value: 'Žagarė', label: 'Žagarė' },
    { value: 'Kudirkos Naumiestis', label: 'Kudirkos Naumiestis' },
    { value: 'Ramygala', label: 'Ramygala' },
    { value: 'Kulautuva', label: 'Kulautuva' },
    { value: 'Skaudvilė', label: 'Skaudvilė' },
    { value: 'Senieji Trakai', label: 'Senieji Trakai' },
    { value: 'Salantai', label: 'Salantai' },
    { value: 'Vilainiai', label: 'Vilainiai' },
    { value: 'Padvariai', label: 'Padvariai' },
    { value: 'Didžiasalis', label: 'Didžiasalis' },
    { value: 'Veisiejai', label: 'Veisiejai' },
    { value: 'Linkuva', label: 'Linkuva' },
    { value: 'Riešė', label: 'Riešė' },
    { value: 'Priekulė', label: 'Priekulė' },
    { value: 'Galgiai', label: 'Galgiai' },
    { value: 'Matuizos', label: 'Matuizos' },
    { value: 'Simnas', label: 'Simnas' },
    { value: 'Ylakiai', label: 'Ylakiai' },
    { value: 'Kairiai', label: 'Kairiai' },
    { value: 'Piniava', label: 'Piniava' },
    { value: 'Varniai', label: 'Varniai' },
    { value: 'Laukuva', label: 'Laukuva' },
    { value: 'Dovilai', label: 'Dovilai' },
    { value: 'Veiveriai', label: 'Veiveriai' },
    { value: 'Užtiltė', label: 'Užtiltė' },
    { value: 'Vidiškės', label: 'Vidiškės' },
    { value: 'Gineitiškės', label: 'Gineitiškės' },
    { value: 'Baltoji Vokė', label: 'Baltoji Vokė' },
    { value: 'Liudvinavas', label: 'Liudvinavas' },
    { value: 'Miklusėnai', label: 'Miklusėnai' },
    { value: 'Mokolai', label: 'Mokolai' },
    { value: 'Plateliai', label: 'Plateliai' },
    { value: 'Joniškėlis', label: 'Joniškėlis' },
    { value: 'Drąsučiai', label: 'Drąsučiai' },
    { value: 'Širvintos', label: 'Širvintos' },
    { value: 'Pagėgiai', label: 'Pagėgiai' },
    { value: 'Kaišiadorys', label: 'Kaišiadorys' },
    { value: 'Raseiniai', label: 'Raseiniai' },
    { value: 'Ignalina', label: 'Ignalina' }
  ]

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCustomEntry, setIsCustomEntry] = useState(false);

  const [inputValueMok, setInputValueMok] = useState('');


  const [serverResponse, setServerResponse] = useState(null)

  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);

  const [value2, setValue2] = useState("no");

  const onSubmit = values => {
    axios
      .post(
        "https://aplinkosaugosegzaminas.lrt.lt/olimpiada/api/register",
        new URLSearchParams({
          ...values,
          rules: values.rules ? 1 : 0,
          newsletter: values.newsletter ? 1 : 0,
          privacy: values.privacy ? 1 : 0,
          city: values.city != null ? values.city.value : "",
          type: value2,
          class1: values.class1 != null ? values.class1.value : "",
          class2: values.class2 != null ? values.class2.value : "",
          gender: values.gender != null ? values.gender.value : "",
          schoolSel: values.schoolSel != null ? values.schoolSel.value : "",
          //education: values.education != null ? values.education.value : "",
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(response => {
        if (response.data.status === "error") {
          setServerResponse(response.data.message)
        } else {
          navigate("/registration-success")
        }
      })
  }

  {/*
  useEffect(() => {
    if (searchTerm) {
      fetch(`https://aplinkosaugosegzaminas.lrt.lt/olimpiada/api/search/${searchTerm}`)
        .then(response => response.json())
        .then(result => {
          setData(result);
          setIsCustomEntry(!result.some(item => item.label === searchTerm));
        })
        .catch(error => console.log('Error fetching data:', error));
    } else {
      setIsCustomEntry(false);
    }
  }, [searchTerm]);

  */}

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    //if (inputValueMok) {
      fetch(`https://aplinkosaugosegzaminas.lrt.lt/olimpiada/api/search2`)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          const formattedData = result.map(item => ({
            value: item.id,
            label: item.name
          }));
          setData(formattedData);
        })
        .catch(error => console.log('Error fetching data:', error));
    //}
  }, [inputValueMok]);

  const handleChange = (value) => {
    setSelectedValue(value);


  };

  const handleInputChange = (inputValueMok) => {
    setInputValueMok(inputValueMok);
  };


  return (
    <Layout>
      <div className="registration-page">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            name: "",
            surname: "",
            email: "",
            emailrep: "",
            city: "",
            rules: false,
            privacy: false,
            age: "",
            organisation: "",
            selectedItem: '',
            schoolSel: ''
          }}
          onSubmit={onSubmit}
          component={( {setFieldValue} ) => (
            <Form>
              {/*<FormObserver  //onSubmit={(e) => e.preventDefault()} />*/}
              <Box>
                <h2 className="tac">Registracija</h2>
                <p>Registruokis į Nacionalinį aplinkosaugos egzaminą ir patikrink savo žinias spalio 24 d. </p>

                <Field type="hidden" name="type" value="0"/>


                <h5 className="tac block-header-left-r">Pasirink dalyvio kategoriją</h5>

                <div className="registration-page--form-row">

                  <div className="box" style={{padding:"0px"}}>

                  <input type="radio" name="select" id="option-1" value="1" checked={value2 === "1"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="select" id="option-2" value="2" checked={value2 === "2"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="select" id="option-3" value="3" checked={value2 === "3"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="select" id="option-4" value="4" checked={value2 === "4"} onChange={(e) => setValue2(e.currentTarget.value)}/>


                          <label htmlFor="option-1" className="option-1">
                            <div className="dot"></div>
                            <div className="text">
                              <strong>Pradedantysis</strong>
                              <p>Jei esi 7–9 klasių moksleivis – ši kategorija skirta tau.</p>
                            </div>
                          </label>


                          <label htmlFor="option-2" className="option-2">
                            <div className="dot"></div>
                            <div className="text">
                              <strong>Smalsuolis</strong>
                              <p>Ši kategorija skirta 10–12 kl. moksleiviams.</p>
                            </div>
                          </label>
                          <label htmlFor="option-3" className="option-3">
                            <div className="dot"></div>
                            <div className="text">
                              <strong>Tyrinėtojas</strong>
                              <p>Nuo studento iki senjoro – ši kategorija skirta plačiajai visuomenei.</p>
                            </div>
                          </label>
                          <label htmlFor="option-4" className="option-4">
                            <div className="dot"></div>
                            <div className="text">
                              <strong>Ekspertas</strong>
                              <p>Kategorija skirta aktyviai aplinkosauga besidomintiems ar šioje srityje dirbantiems dalyviams.</p>
                            </div>
                          </label>
                  </div>
                </div>



                {(value2 !== "no") && (
                <div>

                <h5 className="tac block-header-left-r">Informacija</h5>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="name"
                    label="Vardas"
                  />
                  {/*<Field*/}
                  {/*  component={Input}*/}
                  {/*  type="text"*/}
                  {/*  name="surname"*/}
                  {/*  placeholder=" "*/}
                  {/*  required*/}
                  {/*  label="Pavardė"*/}
                  {/*/>*/}
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="surname"
                    label="Pavardė"
                  />
                  {/*<Field*/}
                  {/*  component={Input}*/}
                  {/*  type="text"*/}
                  {/*  name="surname"*/}
                  {/*  placeholder=" "*/}
                  {/*  required*/}
                  {/*  label="Pavardė"*/}
                  {/*/>*/}
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="email"
                    label="El. pašto adresas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="emailrep"
                    label="Pakartok el. pašto adresą"
                  />
                </div>


                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="age"
                    label="Amžius"
                  />
                </div>



                {/*<div className="registration-page--form-row">*/}
                {/*  /!*<pre>{JSON.stringify(props, null, 2)}</pre>*!/*/}
                {/*  <FormDatePicker />*/}
                {/*</div>*/}






                <div className="registration-page--form-row">
                  <div style={{width:"100%", height: "60px"}}>
                    <label className="labelReg">Lytis<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>

                    <Field name="gender">
                      {({ field, meta, form: { setFieldValue } }) => {
                        return (
                    <Select
                      {...field}
                      placeholder={<div>Pasirinkite...</div>}
                      options={optionsGender}
                      className="selectNae"
                      classNamePrefix="selectNaesel"
                      onChange={val => {
                        setFieldValue("gender", val);
                        console.warn(val.value);
                      }}
                    />
                        );
                      }}
                    </Field>


                  </div>
                </div>







                  <div className="registration-page--form-row">
                    <div style={{width:"100%", height: "60px"}}>
                      <label className="labelReg">Miestas<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>

                  <Field name="city">
                    {({ field, meta, form: { setFieldValue } }) => {
                      return (
                        <CreatableSelect
                          {...field}
                          placeholder={<div>Pasirinkite...</div>}
                          options={optionsCities}
                          className="selectNae"
                          classNamePrefix="selectNaesel"
                          onChange={val => {
                            setFieldValue("city", val);
                            console.warn(val.value);
                          }}
                        />
                      );
                    }}
                  </Field>
                    </div>
                  </div>





                {(value2 === "1") && (
                <div className="registration-page--form-row">
                  <div style={{width:"100%", height: "60px"}}>
                    <label className="labelReg">Klasė<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>


                    <Field name="class1">
                      {({ field, meta, form: { setFieldValue } }) => {
                        return (
                    <Select
                      {...field}
                      placeholder={<div>Pasirinkite...</div>}
                      options={optionsClass1}
                      className="selectNae"
                      classNamePrefix="selectNaesel"
                      onChange={val => {
                        setFieldValue("class1", val);
                        console.warn(val.value);
                      }}
                    />
                        );
                      }}
                    </Field>



                  </div>
                </div>)}


                {(value2 === "2") && (
                <div className="registration-page--form-row">
                  <div style={{width:"100%", height: "60px"}}>
                    <label className="labelReg">Klasė<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>

                    <Field name="class2">
                      {({ field, meta, form: { setFieldValue } }) => {
                        return (
                    <Select
                      {...field}
                      placeholder={<div>Pasirinkite...</div>}
                      options={optionsClass2}
                      className="selectNae"
                      classNamePrefix="selectNaesel"
                      onChange={val => {
                        setFieldValue("class2", val);
                        console.warn(val.value);
                      }}
                    />);
                      }}
                    </Field>



                  </div>
                </div>)}



                {/*<div className="registration-page--form-row">*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    name="email"*/}
                {/*    placeholder=" "*/}
                {/*    required*/}
                {/*    label="El. paštas"*/}
                {/*  />*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    name="city"*/}
                {/*    placeholder=" "*/}
                {/*    required*/}
                {/*    label="Miestas"*/}
                {/*  />*/}
                {/*</div>*/}

                {/*<div className="registration-page--form-row">*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    placeholder=" "*/}
                {/*    required*/}
                {/*    name="age"*/}
                {/*    label="Amžius"*/}
                {/*  />*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    name="occupation"*/}
                {/*    placeholder=" "*/}
                {/*    required*/}
                {/*    label="Mokymosi įstaigos pavadinimas/Darbovietė"*/}
                {/*  />*/}
                {/*</div>*/}

                {/*<div className="registration-page--form-row">*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    placeholder=" "*/}
                {/*    required*/}
                {/*    name="phone"*/}
                {/*    label="Kontaktinis telefono numeris"*/}
                {/*  />*/}
                {/*  <Field*/}
                {/*    component={Input}*/}
                {/*    type="text"*/}
                {/*    name="notes"*/}
                {/*    placeholder=" "*/}
                {/*    label="Pastabos"*/}
                {/*  />*/}
                {/*</div>*/}

                {/*(value2 === "3" || value2 === "4") && (
                <div className="registration-page--form-row">
                  <div style={{width:"100%", height: "60px"}}>
                    <label className="labelReg">Išsilavinimas <sup style={{fontSize:"18px", top:"0"}}>*</sup></label>

                    <Field name="education">
                      {({ field, meta, form: { setFieldValue } }) => {
                        return (
                    <Select
                      {...field}
                      placeholder={<div>Pasirinkite...</div>}
                      options={optionsEducation}
                      className="selectNae"
                      classNamePrefix="selectNaesel"
                      onChange={val => {
                        setFieldValue("education", val);
                        console.warn(val.value);
                      }}
                    />
                        );
                      }}
                    </Field>


                  </div>
                </div>)*/}


                  {(value2 === "1" || value2 === "2") && (

                    <div className="registration-page--form-row">
                      <div style={{width:"100%", height: "60px"}}>

                        <label className="labelReg">Mokykla<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>


                        <Field name="schoolSel">
                          {({ field, meta, form: { setFieldValue } }) => {
                            return (

                        <CreatableSelect
                          options={data}
                          //onInputChange={handleInputChange}
                          //onInputChange={(value) => setInputValueMok(value)}
                          //isSearchable={true}
                         // onChange={handleChange}
                          value={selectedValue}

                          onChange={val => {
                            setFieldValue("schoolSel", val);
                            setSelectedValue(val);
                            console.warn(val.value);
                          }}

                          //onChange={handleChange}
                          className="selectNae"
                          classNamePrefix="selectNaesel"
                          placeholder="Pasirinkite arba įveskite..."
                          isClearable
                          formatCreateLabel={(inputValue) => `Įvesti pavadinimą: "${inputValue}"`}
                        />
                            );
                          }}
                        </Field>



                      </div>
                   </div>)}



                {(value2 === "3" || value2 === "4") && (
                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    name="organisation"
                    label="Atstovaujama įmonė/organizacija (neprivaloma)"
                  />
                </div>
                )}

                  {/*(value2 === "1" || value2 === "2") && (
                    <div className="registration-page--form-row">
                      <Field
                        component={Input}
                        required
                        type="text"
                        placeholder=""
                        name="organisation"
                        label="Mokykla"
                      />
                    </div>
                  )*/}



                <div className="registration-page--form-row">
                  <Field component={Checkbox} name="privacy">
                    <div>
                      Susipažinau ir sutinku su projekto {" "}
                    <a
                      href="https://aplinkosaugosegzaminas.lrt.lt/privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{color:"#EF6C00"}}
                    >
                      privatumo politika.*
                    </a>
                    </div>
                  </Field>
                </div>

                <div className="registration-page--form-row">
                  <Field component={Checkbox} name="newsletter">
                    <div>
                      Sutinku el. paštu gauti edukacinį turinį.
                      {/*{" "}*/}
                      {/*<a*/}
                      {/*  href=""*/}
                      {/*  rel="noopener noreferrer"*/}
                      {/*  target="_blank"*/}
                      {/*>*/}
                      {/*  Taisyklėmis**/}
                      {/*</a>*/}
                    </div>
                  </Field>
                </div>


                </div>)}

    

                {serverResponse && (
                  <div className="registration-page--server-message">
                    {serverResponse}
                  </div>
                )}
              </Box>

              {(value2 !== "no") && (
              <div className="tac">
                <RaisedButton type="submit">Registruotis</RaisedButton>
              </div>
              )}


            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
