import React from "react"
import PropTypes from "prop-types"
import { WithErrorLabel } from "../FormError"
import Styles from "./Checkbox.module.scss"

const Checkbox = ({ children, field, ...props }) => (
  <div className={Styles.wrapper}>
    <label className="bounce">
      <input {...field} {...props} type="checkbox" />

      <span>{children}</span>
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
      </svg>
    </label>
  </div>
)

Checkbox.propTypes = {
  children: PropTypes.any,
  field: PropTypes.object,
}

export default WithErrorLabel(Checkbox)
